
ul.lst-kix_95tlb3bidfwg-0 {
    list-style-type: none
}

ul.lst-kix_71cy10c6bo5c-0 {
    list-style-type: none
}

ul.lst-kix_dpieomdpoi58-0 {
    list-style-type: none
}

.lst-kix_x958alp4at7v-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_x958alp4at7v-0 {
    list-style-type: none
}

.lst-kix_71cy10c6bo5c-0>li:before {
    content: "\0025cf   "
}

.lst-kix_jasfo943ld45-0>li:before {
    content: "\0025cf   "
}

.lst-kix_q43nwmkvrt8-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_ep6cffw0fqwf-0 {
    list-style-type: none
}

ul.lst-kix_jasfo943ld45-0 {
    list-style-type: none
}

.lst-kix_dpieomdpoi58-0>li:before {
    content: "\0025cf   "
}

.lst-kix_ep6cffw0fqwf-0>li:before {
    content: "\0025cf   "
}

.lst-kix_95tlb3bidfwg-0>li:before {
    content: "\0025cf   "
}

li.li-bullet-0:before {
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ul.lst-kix_q43nwmkvrt8-0 {
    list-style-type: none
}

.c5 {
    padding-top: 10pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c2 {
    padding-top: 4pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c12 {
    padding-top: 4pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c13 {
    font-family: "Proxima Nova";
    font-size: 12.5pt;
    padding-top: 4pt;
    padding-bottom: 0pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c3 {
    background-color: #ffffff;
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Proxima Nova";
    font-style: normal
}

.c9 {
    padding-top: 4pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c15 {
    color: #666666;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Proxima Nova";
    font-style: italic
}

.c18 {
    color: #353744;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Proxima Nova";
    font-style: italic
}

.c8 {
    padding-top: 24pt;
    padding-bottom: 10pt;
    line-height: 1.0;
    text-align: left
}

.c29 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c31 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c19 {
    color: #00ab44;
    font-weight: 400;
    font-size: 18pt;
    font-family: "Proxima Nova"
}

.c7 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    
}

.c4 {
    font-size: 10pt;
    font-family: "Proxima Nova";
    color: #666666;
    font-weight: 400
}

.c22 {
    color: #00ab44;
    font-weight: 700;
    font-size: 14pt;
    font-family: "Proxima Nova"
}

.c6 {
    background-color: #ffffff;
    text-decoration: underline;
    vertical-align: baseline;
    font-style: normal

}

.c25 {
    font-size: 12pt;
    font-family: "Proxima Nova";
    color: #353744
}

.c32 {
    color: #353744;
    font-size: 30pt;
    font-family: "Proxima Nova"
}

.c16 {
    font-style: italic;
    color: #666666;
    font-weight: 400
}

.c0 {
    color: inherit;
    text-decoration: inherit
}

.c28 {
    max-width: 600pt;
    margin-left: auto;
    margin-right: auto;
    padding: 18px;
}

.c26 {
    font-size: 12pt;
    font-family: "Proxima Nova"
}

.c10 {
    color: #666666;
    font-size: 10pt
}

.c20 {
    orphans: 2;
    widows: 2
}

.c1 {
    padding: 0;
    margin: 0
}

.c17 {
    height: 11pt
}

.c24 {
    page-break-after: avoid
}

.c30 {
    font-weight: 700
}

.c14 {
    background-color: #ffffff
}

.c23 {
    font-weight: 400
}

.c21 {
    font-style: italic
}

.title {
    background-color: #ffffff;
    padding-top: 24pt;
    color: #353744;
    font-size: 30pt;
    padding-bottom: 12pt;
    font-family: "Proxima Nova";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    background-color: #ffffff;
    padding-top: 0pt;
    color: #00ab44;
    font-size: 18pt;
    padding-bottom: 3pt;
    font-family: "Proxima Nova";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    background-color: #ffffff;
    color: #000000;
    font-size: 11pt;
    font-family: "Proxima Nova"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Proxima Nova"
}

h1 {
    padding-top: 24pt;
    color: #00ab44;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 10pt;
    font-family: "Proxima Nova";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 10pt;
    color: #353744;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Proxima Nova";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}