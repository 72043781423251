ul.lst-kix_95tlb3bidfwg-0, ul.lst-kix_71cy10c6bo5c-0, ul.lst-kix_dpieomdpoi58-0 {
  list-style-type: none;
}

.lst-kix_x958alp4at7v-0 > li:before {
  content: "●  ";
}

ul.lst-kix_x958alp4at7v-0 {
  list-style-type: none;
}

.lst-kix_71cy10c6bo5c-0 > li:before, .lst-kix_jasfo943ld45-0 > li:before, .lst-kix_q43nwmkvrt8-0 > li:before {
  content: "●  ";
}

ul.lst-kix_ep6cffw0fqwf-0, ul.lst-kix_jasfo943ld45-0 {
  list-style-type: none;
}

.lst-kix_dpieomdpoi58-0 > li:before, .lst-kix_ep6cffw0fqwf-0 > li:before, .lst-kix_95tlb3bidfwg-0 > li:before {
  content: "●  ";
}

li.li-bullet-0:before {
  white-space: nowrap;
  min-width: 18pt;
  display: inline-block;
}

ul.lst-kix_q43nwmkvrt8-0 {
  list-style-type: none;
}

.c5 {
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-top: 10pt;
  padding-bottom: 0;
  line-height: 1;
}

.c2 {
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-top: 4pt;
  padding-bottom: 0;
  padding-left: 0;
  line-height: 1.2;
}

.c12 {
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-top: 4pt;
  padding-bottom: 0;
  line-height: 1;
}

.c13 {
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-top: 4pt;
  padding-bottom: 0;
  font-family: Proxima Nova;
  font-size: 12.5pt;
  line-height: 1.2;
}

.c3 {
  color: #000;
  vertical-align: baseline;
  background-color: #fff;
  font-family: Proxima Nova;
  font-size: 12pt;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.c9 {
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-top: 4pt;
  padding-bottom: 0;
  padding-left: 0;
  line-height: 1;
}

.c15 {
  color: #666;
  vertical-align: baseline;
  font-family: Proxima Nova;
  font-size: 12pt;
  font-style: italic;
  font-weight: 400;
  text-decoration: none;
}

.c18 {
  color: #353744;
  vertical-align: baseline;
  font-family: Proxima Nova;
  font-size: 12pt;
  font-style: italic;
  font-weight: 400;
  text-decoration: none;
}

.c8 {
  text-align: left;
  padding-top: 24pt;
  padding-bottom: 10pt;
  line-height: 1;
}

.c29 {
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;
}

.c31 {
  text-align: left;
  padding-top: 6pt;
  padding-bottom: 0;
  line-height: 1;
}

.c19 {
  color: #00ab44;
  font-family: Proxima Nova;
  font-size: 18pt;
  font-weight: 400;
}

.c7 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #15c;
}

.c4 {
  color: #666;
  font-family: Proxima Nova;
  font-size: 10pt;
  font-weight: 400;
}

.c22 {
  color: #00ab44;
  font-family: Proxima Nova;
  font-size: 14pt;
  font-weight: 700;
}

.c6 {
  vertical-align: baseline;
  background-color: #fff;
  font-style: normal;
  text-decoration: underline;
}

.c25 {
  color: #353744;
  font-family: Proxima Nova;
  font-size: 12pt;
}

.c32 {
  color: #353744;
  font-family: Proxima Nova;
  font-size: 30pt;
}

.c16 {
  color: #666;
  font-style: italic;
  font-weight: 400;
}

.c0 {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.c28 {
  max-width: 600pt;
  margin-left: auto;
  margin-right: auto;
  padding: 18px;
}

.c26 {
  font-family: Proxima Nova;
  font-size: 12pt;
}

.c10 {
  color: #666;
  font-size: 10pt;
}

.c20 {
  orphans: 2;
  widows: 2;
}

.c1 {
  margin: 0;
  padding: 0;
}

.c17 {
  height: 11pt;
}

.c24 {
  page-break-after: avoid;
}

.c30 {
  font-weight: 700;
}

.c14 {
  background-color: #fff;
}

.c23 {
  font-weight: 400;
}

.c21 {
  font-style: italic;
}

.title {
  color: #353744;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
  background-color: #fff;
  padding-top: 24pt;
  padding-bottom: 12pt;
  font-family: Proxima Nova;
  font-size: 30pt;
  line-height: 1;
}

.subtitle {
  color: #00ab44;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 3pt;
  font-family: Proxima Nova;
  font-size: 18pt;
  line-height: 1;
}

li {
  color: #000;
  background-color: #fff;
  font-family: Proxima Nova;
  font-size: 11pt;
}

p {
  color: #000;
  margin: 0;
  font-family: Proxima Nova;
  font-size: 11pt;
}

h1 {
  color: #00ab44;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-top: 24pt;
  padding-bottom: 10pt;
  font-family: Proxima Nova;
  font-size: 14pt;
  font-weight: 700;
  line-height: 1;
}

h2 {
  color: #353744;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-top: 10pt;
  padding-bottom: 0;
  font-family: Proxima Nova;
  font-size: 12pt;
  font-weight: 700;
  line-height: 1;
}
/*# sourceMappingURL=index.f4a0b3e9.css.map */
